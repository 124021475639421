<template>
  <div class="">
    <!-- eslint-disable -->
    <amplify-authenticator username-alias="email">
      <amplify-sign-in
        slot="sign-in"
        header-text="Women Headed Family Survey"
        :hide-sign-up="true"
        username-alias="email"
      ></amplify-sign-in>
    </amplify-authenticator>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import {onAuthUIStateChange} from '@aws-amplify/ui-components';
import {mapActions} from 'vuex';

export default {
  created() {
    this.unsubscribeAuth = onAuthUIStateChange(async (authState, authData) => {
      await this.setLoginState(authData);
      this.$router.push({name: 'home'});
      this.authState = authState;
      this.user = authData;
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
    };
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
  methods: {
    ...mapActions({
      setLoginState: 'auth/setLoginState',
    }),
  },
};
</script>
